import React from 'react';

import Layout from '../layouts';
import Container from '../components/Container';

import loadComponents from '../components/Loadable';

const Button = loadComponents('button');
const SubpageHeader = loadComponents('subpage-header');

export default function NotFoundPage({ location }) {
  const prevPage = location.state && location.state.prevPage;
  const pageTitle = `404 Page Not Found`;
  return (
    <Layout
      title={pageTitle}
      seo={null}
      prevPage={prevPage}
      padding="0 0 5.625rem"
    >
      <SubpageHeader title={pageTitle} />
      <Container>
        <p>It looks like nothing was found at this location.</p>
        <Button
          to="/"
          style={{ justifyContent: `center`, maxWidth: `300px` }}
          primary
        >
          Return Home
        </Button>
      </Container>
    </Layout>
  );
}
